<template>
  <div class="article-item">
    <div class="header">
      <avatar
        size="24"
        :avatar-img="
          article.user?.avatar
            ? `${article.user?.avatar}?f=png&w=24&height=24`
            : ''
        "
      ></avatar>
      <span class="sub-text-s3">{{ state.displayName }}</span>
      <span class="created-at sub-text-s3 text-gray-second">
        {{ state.createdAt }}
      </span>
    </div>

    <h5 class="tit">{{ article.title }}</h5>
    <p
      class="content b-text-2 text-gray-second"
      v-html="article.contentHtml"
    ></p>
  </div>
</template>

<script>
import Avatar from "../../../../components/console/avatars/Avatar";
import { computed, reactive } from "vue";
import helper from "@/helper";
import { savedAt } from "../../../../helper/date";

export default {
  name: "DashboardArticleItem",
  components: { Avatar },
  props: {
    article: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const state = reactive({
      displayName: computed(() => {
        return helper.displayUserName(props.article.user);
      }),
      createdAt: computed(() => {
        return savedAt(props.article.createdAt);
      }),
    });

    return { state };
  },
};
</script>

<style src="./style.css" scoped></style>
