<template>
  <icon-base
    icon-name="plus-icon"
    :view-box="viewBox"
    :width="width"
    :height="height"
    :icon-color="iconColor"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.5 2.5C8.5 2.22386 8.27614 2 8 2C7.72386 2 7.5 2.22386 7.5 2.5V7.5H2.5C2.22386 7.5 2 7.72386 2 8C2 8.27614 2.22386 8.5 2.5 8.5H7.5V13.5C7.5 13.7761 7.72386 14 8 14C8.27614 14 8.5 13.7761 8.5 13.5V8.5H13.5C13.7761 8.5 14 8.27614 14 8C14 7.72386 13.7761 7.5 13.5 7.5H8.5V2.5Z"
      :fill="fillColor"
      :stroke="fillColor"
      :stroke-width="state.strokeWidth"
    />
  </icon-base>
</template>
<script>
import IconBase from "../../common/IconBase";
import { computed, reactive } from "vue";

export default {
  name: "PlusIcon",
  components: { IconBase },
  props: {
    viewBox: {
      type: String,
      default: "0 0 16 16",
    },
    width: {
      type: [Number, String],
      default: 16,
    },
    height: {
      type: [Number, String],
      default: 16,
    },
    iconColor: {
      type: String,
      default: "currentColor",
    },
    fillColor: {
      type: String,
      default: "#0D0D10",
    },
    strokeSize: {
      type: String,
      default: "thin",
      validator(value) {
        return ["thin", "normal"].includes(value);
      },
    },
  },
  setup(props) {
    const state = reactive({
      strokeWidth: computed(() => {
        if (props.strokeSize === "normal") {
          return 0.5;
        }
        return 0;
      }),
    });

    return { state };
  },
};
</script>
