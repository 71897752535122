<template>
  <button
    class="text-btn"
    :class="{
      'exist-icon': isIcon,
      reverse: iconPosition === 'back',
    }"
    :style="{
      padding: padding,
    }"
    :disabled="disabled"
  >
    <slot name="icon"></slot>

    <span :class="`sub-${textWeight}-${textSize}`" :style="{ color: color }">
      {{ text }}
    </span>
  </button>
</template>

<script>
export default {
  name: "ButtonText",
  components: {},
  props: {
    isIcon: {
      type: Boolean,
      required: false,
    },
    iconPosition: {
      type: String,
      default: "back",
      validator(value) {
        return ["back", "front"].includes(value);
      },
    },
    text: {
      type: String,
      default: "",
    },
    textSize: {
      type: String,
      default: "s3",
      validator(value) {
        return ["s1", "s2", "s3"].includes(value);
      },
    },
    textWeight: {
      type: String,
      default: "text",
      validator(value) {
        return ["text", "title"].includes(value);
      },
    },
    color: {
      type: String,
      default: "#0D0D10",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    padding: {
      type: String,
      default: "14px 16px",
    },
  },
};
</script>

<style scoped>
.text-btn {
  display: flex;
  align-content: center;
}

.text-btn.exist-icon {
  display: flex;
  align-items: center;
  gap: 2px;
  padding: 0;
}

.text-btn.reverse {
  flex-flow: row-reverse;
}

.text-btn[disabled] {
  color: #d0d5de !important;
  background: #ffffff !important;
}
.text-btn:disabled {
  cursor: default;
}
</style>
