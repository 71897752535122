<template>
  <div class="content-item">
    <div
      class="image"
      :style="{
        backgroundImage: `url('${club.featuredImage}?f=png&w=51&h=64')`,
      }"
    ></div>
    <div class="meta">
      <p class="title sub-text-s2">
        {{ club.title }}
      </p>
    </div>
  </div>
</template>

<script>
import { computed, getCurrentInstance, reactive } from "vue";

export default {
  name: "DashboardClubItem",
  props: {
    club: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { proxy } = getCurrentInstance();

    const state = reactive({
      sessionType: computed(() => {
        if (!props.club.theme) {
          return {
            text: "",
            color: "",
          };
        }
        if (props.club.theme.typeLabel === "VOD") {
          return proxy.$const.sessionTypes.vod;
        }
        if (props.club.theme.typeLabel === "Live") {
          return proxy.$const.sessionTypes.live;
        }
        return proxy.$const.sessionTypes.content;
      }),
    });

    return { state };
  },
};
</script>

<style src="./style.css" scoped></style>
