<template>
  <div
    class="card-empty"
    :style="{
      padding: padding,
    }"
  >
    <slot name="iconImage"></slot>
    <p class="sub-text-s2 text-gray-second" v-html="text"></p>
  </div>
</template>

<script>
export default {
  name: "EmptyCard",
  props: {
    padding: {
      type: String,
      default: "0",
    },
    text: {
      type: String,
      default: "",
    },
  },
};
</script>

<style scoped>
.card-empty {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 12px;
}
</style>
