<template>
  <div class="page-container-new">
    <page-loading v-if="state.pageLoading"></page-loading>
    <div v-if="!state.pageLoading">
      <page-header title="대시보드"> </page-header>

      <div class="dashboard-wrapper">
        <!--	대시보드 stat  -->
        <div class="stats-card">
          <div class="stat-earnings">
            <span class="sub-text-s3 text-gray-third"> 판매 금액 (원) </span>
            <h2 class="text-gray-reverse value">{{ state.earningsTotal }}</h2>
          </div>

          <div class="stat-sales-ticket">
            <span class="sub-text-s3 text-gray-third">판매된 티켓 (개)</span>
            <h2 class="text-gray-reverse value">
              {{ state.salesTicketsTotal }}
            </h2>
          </div>

          <div class="stat-paid-users">
            <h4 class="text-gray-reverse value">
              {{ state.paidUsersTotal }}
            </h4>
            <span class="sub-text-s3 text-gray-third">구매한 팬 (명)</span>
          </div>

          <div class="stat-connects">
            <h4 class="text-gray-reverse value">{{ state.connectsTotal }}</h4>
            <span class="sub-text-s3 text-gray-third">연결된 구독자 (명)</span>
          </div>

          <div class="stat-articles">
            <h4 class="text-gray-reverse value">{{ state.articlesTotal }}</h4>
            <span class="sub-text-s3 text-gray-third">
              커뮤니티 게시글 (개)
            </span>
          </div>

          <div class="stat-comments">
            <h4 class="text-gray-reverse value">{{ state.commentsTotal }}</h4>
            <span class="sub-text-s3 text-gray-third">커뮤니티 댓글 (개)</span>
          </div>
        </div>

        <div>
          <!--	수익 그래프  -->
          <div class="earning-graph__wrapper">
            <card-basic class="card-comp earning-graph">
              <div class="header">
                <h4>수익 그래프</h4>
                <button-dropdown
                  distance="4"
                  :auto-hide="false"
                  :shown="state.showCalendarTooltip"
                >
                  <template #button>
                    <button-text
                      :is-icon="true"
                      :text="state.selectedDate"
                      text-size="s1"
                      padding="0"
                      @click="actions.openCalendar()"
                    >
                      <template #icon>
                        <arrow-icon
                          direction="down"
                          stroke-size="normal"
                        ></arrow-icon>
                      </template>
                    </button-text>
                  </template>
                  <template #dropdownList>
                    <div class="month-calendar">
                      <div class="header">
                        <span class="sub-title-s1">
                          {{ `${state.selectedYear}년` }}
                        </span>
                        <div class="directions">
                          <button-text
                            :is-icon="true"
                            padding="0"
                            @click="actions.setSelectedYear(-1)"
                          >
                            <template #icon>
                              <arrow-icon
                                direction="left"
                                width="20"
                                height="20"
                                :fill-color="
                                  state.existPrevYear ? '#0D0D10' : '#D0D5DE'
                                "
                              ></arrow-icon>
                            </template>
                          </button-text>
                          <button-text
                            :is-icon="true"
                            @click="actions.setSelectedYear(1)"
                          >
                            <template #icon>
                              <arrow-icon
                                direction="right"
                                width="20"
                                height="20"
                                :fill-color="
                                  state.isNextYear ? '#0D0D10' : '#D0D5DE'
                                "
                              ></arrow-icon>
                            </template>
                          </button-text>
                        </div>
                      </div>

                      <ul class="month-list">
                        <li v-for="month in 12" :key="`month-${month}`">
                          <button-basic
                            padding="11px 0"
                            border-color="#8E1EFF"
                            bg-color="#ffffff"
                            hover-bg-color="#F6F8FA"
                            text-weight="title"
                            :border="activeMonth(month)"
                            :color="activeMonth(month) ? '#8E1EFF' : '#0D0D10'"
                            :text="`${month}월`"
                            :disabled="
                              isDisabledMonth(state.selectedYear, month)
                            "
                            :disabled-style="{
                              border: 'none',
                              backgroundColor: '#ffffff',
                              color: '#ecf1f4',
                            }"
                            @action="actions.getPaymentGraph(month)"
                          ></button-basic>
                        </li>
                      </ul>
                    </div>
                  </template>
                </button-dropdown>
              </div>
              <div class="chart">
                <apexchart
                  height="288"
                  :options="state.paymentChartOption.chartOptions"
                  :series="state.paymentChartOption.series"
                ></apexchart>
              </div>
            </card-basic>
          </div>

          <!--	공지사항, 예정된 라이브 일정  -->
          <div class="notice-live__group">
            <!--	공지사항  -->
            <div class="notice-card__wrapper">
              <card-basic class="card-comp notice-card">
                <div class="header" @click="actions.goToNotices()">
                  <h4>공지사항</h4>
                  <button-text padding="0" :is-icon="true">
                    <template #icon>
                      <arrow-icon stroke-size="normal"></arrow-icon>
                    </template>
                  </button-text>
                </div>
                <ul class="notice-list">
                  <li
                    v-for="notice in state.notices"
                    :key="`notice-${notice.resourceId}`"
                    @click="actions.goToNoticeDetail(notice.resourceId)"
                  >
                    <span class="text-blue-50 sub-text-s3">
                      {{ notice.isMust ? "필독공지" : "업데이트" }}
                    </span>
                    <span class="tit sub-text-s2">
                      {{ notice.title }}
                    </span>
                  </li>
                </ul>
              </card-basic>
            </div>
            <!--	예정된 라이브 일정  -->
            <div class="meeting-card__wrapper">
              <card-basic class="card-comp meeting-card">
                <div class="header">
                  <h4>예정된 라이브 일정</h4>
                </div>
                <ul v-if="state.meetings.length > 0" class="meeting-list">
                  <li
                    v-for="meeting in state.meetings"
                    :key="`meeting-${meeting.resourceId}`"
                  >
                    <dashboard-meeting-item
                      :meeting="meeting"
                      @click="actions.goToClubDetail(meeting.club.resourceId)"
                    ></dashboard-meeting-item>
                  </li>
                </ul>
                <button-basic
                  v-if="state.meetings.length === 1"
                  class="meeting-add__btn"
                  text="라이브 추가하기"
                  text-size="s2"
                  bg-color="#ffffff"
                  color="#0D0D10"
                  border-color="#E6EAEF"
                  :border="true"
                  padding="8px 0"
                  @action="actions.goToThemes()"
                >
                  <template #icon>
                    <plus-icon
                      stroke-size="normal"
                      width="12"
                      height="12"
                    ></plus-icon>
                  </template>
                </button-basic>
                <div v-if="state.meetings.length === 0" class="empty">
                  <p class="txt sub-text-s2 text-gray-second">
                    다가오는 라이브가 없어요.
                  </p>
                  <button-basic
                    class="meeting-add__btn"
                    text="라이브 추가하기"
                    text-size="s2"
                    color="#0D0D10"
                    bg-color="#ffffff"
                    border-color="#E6EAEF"
                    :border="true"
                    padding="10px 0"
                    @action="actions.goToThemes()"
                  >
                    <template #icon>
                      <plus-icon
                        stroke-size="normal"
                        width="12"
                        height="12"
                      ></plus-icon>
                    </template>
                  </button-basic>
                </div>
              </card-basic>
            </div>
          </div>

          <!--  많이 판매된 콘텐츠 -->
          <div class="club-card__wrapper">
            <card-basic class="card-comp club-card">
              <div class="header" @click="actions.goToClubs()">
                <h4>많이 판매된 콘텐츠</h4>
                <button-text padding="0" :is-icon="true">
                  <template #icon>
                    <arrow-icon stroke-size="normal"></arrow-icon>
                  </template>
                </button-text>
              </div>

              <ul v-if="state.contents.length > 0" class="club-list">
                <li
                  v-for="club in state.contents"
                  :key="`dashboard-club-${club.resourceId}`"
                >
                  <dashboard-club-item
                    :club="club"
                    @click="actions.goToClubDetail(club.resourceId)"
                  ></dashboard-club-item>
                </li>
              </ul>
              <empty-card
                v-if="state.contents.length === 0"
                text="판매된 콘텐츠가 없어요."
                padding="105px 0"
              >
                <template #iconImage>
                  <info-icon width="32" height="32"></info-icon>
                </template>
              </empty-card>
            </card-basic>
          </div>

          <!--  많이 구매한 팬 -->
          <div class="user-card__wrapper">
            <card-basic class="card-comp user-card">
              <div class="header" @click="actions.goToMembers()">
                <h4>많이 구매한 팬</h4>
                <button-text padding="0" :is-icon="true">
                  <template #icon>
                    <arrow-icon stroke-size="normal"></arrow-icon>
                  </template>
                </button-text>
              </div>

              <ul v-if="state.paidUsers.length > 0" class="user-list">
                <li
                  v-for="(paidUser, index) in state.paidUsers"
                  :key="`dashboard-fan-${paidUser.resourceId}`"
                >
                  <dashboard-fan-item
                    :number="index + 1"
                    :fan="paidUser"
                    @click="actions.goToMembers(paidUser.name)"
                  ></dashboard-fan-item>
                </li>
              </ul>
              <empty-card
                v-if="state.paidUsers.length === 0"
                text="콘텐츠를 구매한 유저가 없어요."
                padding="105px 0"
              >
                <template #iconImage>
                  <info-icon width="32" height="32"></info-icon>
                </template>
              </empty-card>
            </card-basic>
          </div>

          <!--  최근 게시글 -->
          <div class="article-card__wrapper">
            <card-basic class="card-comp article-card">
              <div class="header" @click="actions.goToArticles()">
                <h4>최근 게시글</h4>
                <button-text padding="0" :is-icon="true">
                  <template #icon>
                    <arrow-icon stroke-size="normal"></arrow-icon>
                  </template>
                </button-text>
              </div>

              <ul v-if="state.articles.length > 0" class="article-list">
                <li
                  v-for="article in state.articles"
                  :key="`dashboard-article-${article.resourceId}`"
                >
                  <dashboard-article-item
                    :article="article"
                    @click="actions.goToArticles()"
                  ></dashboard-article-item>
                </li>
              </ul>
              <empty-card
                v-if="state.articles.length === 0"
                text="커뮤니티에 작성된 글이 없어요."
                padding="105px 0"
              >
                <template #iconImage>
                  <info-icon width="32" height="32"></info-icon>
                </template>
              </empty-card>
            </card-basic>
          </div>

          <!--  구매 전환율  -->
          <div class="payment-ratio-wrapper">
            <card-basic class="card-comp payment-ratio-card">
              <div class="header">
                <h4>구매 전환율</h4>
              </div>
              <div class="chart">
                <apexchart
                  height="288"
                  :options="state.transformChartOption.chartOptions"
                  :series="state.transformChartOption.series"
                ></apexchart>
                <div class="text">
                  <h3>방문자의 32%가 구매했어요!</h3>
                  <p class="c-text text-gray-second">* 평균 구매 전환율 1.7%</p>
                </div>

                <div class="locked">
                  <locked-icon width="32" height="32"></locked-icon>
                  <p class="sub-text-s2 text-gray-second">준비중입니다.</p>
                </div>
              </div>
            </card-basic>
          </div>

          <!--	유입 채널  -->
          <div class="channel-wrapper">
            <card-basic class="card-comp channel-card">
              <div class="header">
                <h4>유입 채널</h4>
              </div>

              <div class="channel-static">
                <div class="chart">
                  <apexchart
                    :options="state.channelChartOption.chartOptions"
                    :series="state.channelChartOption.series"
                  ></apexchart>
                  <div class="data">
                    <div class="percent">
                      <span class="value">56</span>
                      <span class="unit">%</span>
                    </div>
                    <div class="top-channel">
                      <span class="text-gray-second sub-text-s2"
                        >직접 유입</span
                      >
                      <span class="text-blue-50 sub-text-s3">7023</span>
                    </div>
                  </div>
                </div>

                <ul class="channel-list">
                  <li>
                    <div class="channel-item">
                      <span class="channel">
                        <div class="dot dot-1"></div>
                        <span>직접유입</span>
                      </span>
                      <span class="cnt">7123</span>
                    </div>
                  </li>
                  <li>
                    <div class="channel-item">
                      <span class="channel">
                        <div class="dot dot-2"></div>
                        <span>네이버</span>
                      </span>
                      <span class="cnt">2723</span>
                    </div>
                  </li>
                  <li>
                    <div class="channel-item">
                      <span class="channel">
                        <div class="dot dot-3"></div>
                        <span>다음</span>
                      </span>
                      <span class="cnt">1832</span>
                    </div>
                  </li>
                  <li>
                    <div class="channel-item">
                      <span class="channel">
                        <div class="dot dot-4"></div>
                        <span>구글</span>
                      </span>
                      <span class="cnt">947</span>
                    </div>
                  </li>
                  <li>
                    <div class="channel-item">
                      <span class="channel">
                        <div class="dot dot-5"></div>
                        <span>페이스북</span>
                      </span>
                      <span class="cnt">808</span>
                    </div>
                  </li>
                </ul>

                <ul class="channel-list-second">
                  <li>
                    <div class="channel-item">
                      <span class="channel">
                        <div class="dot dot-6"></div>
                        <span>카카오</span>
                      </span>
                      <span class="cnt">193</span>
                    </div>
                  </li>
                  <li>
                    <div class="channel-item">
                      <span class="channel">
                        <div class="dot dot-7"></div>
                        <span>유튜브</span>
                      </span>
                      <span class="cnt">97</span>
                    </div>
                  </li>
                  <li>
                    <div class="channel-item">
                      <span class="channel">
                        <div class="dot dot-8"></div>
                        <span>인스타그램</span>
                      </span>
                      <span class="cnt">88</span>
                    </div>
                  </li>
                  <li>
                    <div class="channel-item">
                      <span class="channel">
                        <div class="dot dot-9"></div>
                        <span>트위터</span>
                      </span>
                      <span class="cnt">74</span>
                    </div>
                  </li>
                  <li>
                    <div class="channel-item">
                      <span class="channel">
                        <div class="dot dot-10"></div>
                        <span>기타</span>
                      </span>
                      <span class="cnt">67</span>
                    </div>
                  </li>
                </ul>

                <div class="locked">
                  <locked-icon width="32" height="32"></locked-icon>
                  <p class="sub-text-s2 text-gray-second">준비중입니다.</p>
                </div>
              </div>
            </card-basic>
          </div>

          <!--	유입 국가  -->
          <div class="country-wrapper">
            <card-basic class="card-comp country-card">
              <div class="header">
                <h4>유입 국가</h4>
              </div>
              <div class="chart">
                <img
                  src="/assets/images/dashboard/world_map.png"
                  height="288"
                />
                <ul>
                  <li>
                    <div class="country-item">
                      <span class="country">대한민국</span>
                      <div class="bar-wrapper">
                        <div class="bar" style="width: 76%"></div>
                      </div>
                      <span class="percent">76%</span>
                    </div>
                  </li>
                  <li>
                    <div class="country-item">
                      <span class="country">미국</span>
                      <div class="bar-wrapper">
                        <div class="bar" style="width: 12%"></div>
                      </div>
                      <span class="percent">12%</span>
                    </div>
                  </li>
                  <li>
                    <div class="country-item">
                      <span class="country">일본</span>
                      <div class="bar-wrapper">
                        <div class="bar" style="width: 3%"></div>
                      </div>
                      <span class="percent">3%</span>
                    </div>
                  </li>
                  <li>
                    <div class="country-item">
                      <span class="country">호주</span>
                      <div class="bar-wrapper">
                        <div class="bar" style="width: 1%"></div>
                      </div>
                      <span class="percent">1%</span>
                    </div>
                  </li>
                  <li>
                    <div class="country-item">
                      <span class="country">캐나다</span>
                      <div class="bar-wrapper">
                        <div class="bar" style="width: 1%"></div>
                      </div>
                      <span class="percent">1%</span>
                    </div>
                  </li>
                </ul>
                <div class="locked">
                  <locked-icon width="32" height="32"></locked-icon>
                  <p class="sub-text-s2 text-gray-second">준비중입니다.</p>
                </div>
              </div>
            </card-basic>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onBeforeMount, reactive } from "vue";
import { useStore } from "vuex";
import PageHeader from "../../../components/console/headers/PageHeader";
import PageLoading from "../../../components/console/loadings/PageLoading";
import helper from "@/helper";
import ArrowIcon from "../../../components/console/icons/ArrowIcon";
import InfoIcon from "../../../components/console/icons/InfoIcon";
import moment from "moment-timezone";
import LockedIcon from "../../../components/console/icons/LockedIcon";
import { savedAt, dateFormat } from "@/helper/date";
import { useRouter } from "vue-router";
import DashboardClubItem from "./DashboardClubItem/DashboardClubItem";
import CardBasic from "../../../components/console/cards/CardBasic";
import ButtonText from "../../../components/console/buttons/ButtonText";
import ButtonBasic from "../../../components/console/buttons/ButtonBasic";
import PlusIcon from "../../../components/console/icons/PlusIcon";
import DashboardMeetingItem from "./DashboardMeetingItem/DashboardMeetingItem";
import DashboardFanItem from "./DashboardFanItem/DashboardFanItem";
import DashboardArticleItem from "./DashboardArticleItem/DashboardArticleItem";
import EmptyCard from "../../../components/console/empties/EmptyCard";
import ButtonDropdown from "../../../components/console/buttons/ButtonDropdown";

export default {
  name: "Dashboard",
  components: {
    ButtonDropdown,
    EmptyCard,
    DashboardArticleItem,
    DashboardFanItem,
    DashboardMeetingItem,
    PlusIcon,
    ButtonBasic,
    ButtonText,
    CardBasic,
    DashboardClubItem,
    LockedIcon,
    InfoIcon,
    ArrowIcon,
    PageHeader,
    PageLoading,
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const currentAt = moment();
    const earningDateParamFormat = "YYYYMM";
    const yearFormat = "YYYY";

    const state = reactive({
      pageLoading: true,
      user: computed(() => {
        return store.getters["auth/user"];
      }),
      dashboard: computed(() => {
        return store.getters["dashboard/dashboard"];
      }),
      earningsTotal: computed(() => {
        return `${helper.getCurrencyUnit("KRW")}${helper.priceFormat(
          state.dashboard.stats.earnings.total
        )}`;
      }),
      salesTicketsTotal: computed(() => {
        return helper.priceFormat(state.dashboard.stats.salesTickets.total);
      }),
      paidUsersTotal: computed(() => {
        return helper.priceFormat(state.dashboard.stats.paidUsers.total);
      }),
      connectsTotal: computed(() => {
        return helper.priceFormat(state.dashboard.stats.connects.total);
      }),
      articlesTotal: computed(() => {
        return helper.priceFormat(state.dashboard.stats.articles.total);
      }),
      commentsTotal: computed(() => {
        return helper.priceFormat(state.dashboard.stats.comments.total);
      }),
      notices: computed(() => {
        return state.dashboard.notices.slice(0, 2);
      }),
      meetings: computed(() => {
        return state.dashboard.meetings.slice(0, 2);
      }),
      graphData: computed(() => {
        return store.getters["dashboard/graphData"];
      }),
      contents: computed(() => {
        return state.dashboard.clubs;
      }),
      paidUsers: computed(() => {
        return state.dashboard.paidUsersRank;
      }),
      articles: computed(() => {
        return state.dashboard.articles;
      }),
      selectedDate: computed(() => {
        return `${state.graphData.year}년 ${state.graphData.month}월`;
      }),
      selectedMonth: "",
      selectedYear: "",
      graphDateParam: computed(() => {
        return `${state.selectedYear}${state.selectedMonth}`;
      }),
      yearIndex: 0,
      existPrevYear: computed(() => {
        return (
          moment(state.user.createdAt).format(yearFormat) < state.selectedYear
        );
      }),
      isNextYear: computed(() => {
        return currentAt.format(yearFormat) > state.selectedYear;
      }),
      showCalendarTooltip: false,
      paymentChartOption: {
        series: [
          {
            name: "수익",
            data: [],
          },
        ],
        chartOptions: {
          chart: {
            type: "area",
            toolbar: {
              show: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: "smooth",
          },
          xaxis: {
            labels: {
              format: "dd",
            },
            type: "category",
          },
          tooltip: {
            x: {
              format: "yyyy/MM/dd",
            },
            y: {
              formatter: function (val) {
                return `${helper.priceFormat(val)} 원`;
              },
            },
          },
          grid: {
            paddingBottom: -20,
          },
          responsive: [
            {
              breakpoint: 768,
              options: {
                xaxis: {
                  tickAmount: 6,
                },
              },
            },
          ],
        },
      },
      transformChartOption: {
        series: [44],
        chartOptions: {
          colors: ["#00A3FF"],
          title: {
            show: false,
          },
          chart: {
            type: "radialBar",
          },
          labels: [""],
          plotOptions: {
            radialBar: {
              width: 120,
              offsetY: 34,
              startAngle: -90,
              endAngle: 90,
              hollow: {
                margin: 15,
                size: "70%",
                imageWidth: 300,
              },
              track: {
                background: "#F6F8FA",
                startAngle: -90,
                endAngle: 90,
              },
              dataLabels: {
                show: "always",
                name: {
                  show: true,
                  color: "#888",
                  fontSize: "13px",
                },
                value: {
                  show: true,
                  color: "#00A3FF",
                  fontSize: "30px",
                  offsetY: -30,
                },
              },
            },
          },
          stroke: {
            lineCap: "round",
          },
        },
      },
      channelChartOption: {
        series: [7123, 2723, 1832, 947, 808, 193, 97, 88, 74, 67],
        chartOptions: {
          chart: {
            type: "donut",
            offsetY: -10,
            toolbar: {
              show: false,
            },
            height: "100%",
          },
          title: {
            show: false,
          },
          legend: {
            show: false,
          },
          plotOptions: {
            pie: {
              donut: {
                size: "90%",
              },
              offsetY: 30,
            },
          },
          dataLabels: {
            enabled: false,
          },
          colors: [
            "#B28BFF",
            "#4EC53D",
            "#F4B935",
            "#5186EC",
            "#2260D9",
            "#F9E000",
            "#FF0000",
            "#D823CC",
            "#25B6F4",
            "#D0D5DE",
          ],
          labels: [
            "직접유입",
            "네이버 블로그",
            "네이버 검색",
            "구글",
            "페이스북",
            "라인",
            "유튜브",
            "인스타그램",
            "트위터",
            "기타",
          ],
        },
      },
    });

    onBeforeMount(() => {
      DashboardInit();
    });

    const DashboardInit = async () => {
      await store.dispatch("dashboard/getDashboard");
      await getPaymentEarningGraph();
      state.pageLoading = false;
    };

    const setPaymentChartOption = () => {
      state.selectedYear = state.graphData.year;
      state.selectedMonth = state.graphData.month;

      const dayCnt = parseInt(
        state.graphData.endDay - state.graphData.startDay + 1
      );

      state.paymentChartOption.series[0].data = [];

      for (let i = 0; i < dayCnt; i++) {
        let startDayString = `${state.graphData.year}-${state.graphData.month}-${state.graphData.startDay}`;
        const startDay = moment(startDayString);

        state.paymentChartOption.series[0].data.push({
          x: startDay.add(i, "days").format("DD"),
          y: 0,
        });

        let existDataIndex = state.graphData.payments.findIndex((item) => {
          return item.day === i + 1;
        });

        if (existDataIndex >= 0) {
          state.paymentChartOption.series[0].data[i].y =
            state.graphData.payments[existDataIndex].amount;
        }
      }
    };

    const activeMeeting = (meeting) => {
      return (
        moment(meeting.scheduledAt).format("YYYYMMDD") ===
        currentAt.format("YYYYMMDD")
      );
    };

    const getPaymentEarningGraph = async () => {
      let payload = {
        month: state.graphDateParam,
      };

      await store
        .dispatch("dashboard/getUserSelectedMonthEarningGraph", payload)
        .then(() => {
          setPaymentChartOption();
          state.showCalendarTooltip = false;
        });
    };

    const isDisabledMonth = (year, month) => {
      const currentMonth = currentAt.format(earningDateParamFormat);
      const selectedMonth = year.toString() + makeMonthFormat(month);
      const createdMonth = moment(state.user.createdAt).format(
        earningDateParamFormat
      );

      return (
        parseInt(createdMonth) > parseInt(selectedMonth) ||
        parseInt(currentMonth) < parseInt(selectedMonth)
      );
    };

    const activeMonth = (month) => {
      return (
        `${state.graphData.year}${state.graphData.month}` ===
        `${state.selectedYear}${makeMonthFormat(month)}`
      );
    };

    const makeMonthFormat = (month) => {
      return ("00" + month.toString()).slice(-2);
    };

    const actions = {
      getPaymentGraph: (month) => {
        state.showCalendarTooltip = false;
        if (!activeMonth(month)) {
          state.selectedMonth = makeMonthFormat(month);
          getPaymentEarningGraph();
        }
      },
      openCalendar: () => {
        state.showCalendarTooltip = true;
      },
      goToNotices: () => {
        router.push({ name: "console.notices" });
      },
      openMeetingListModal: () => {
        state.showMeetingListModal = true;
      },
      goToThemes: () => {
        router.push({ name: "console.themes" });
      },
      goToClubs: () => {
        router.push({ name: "console.clubs" });
      },
      goToClubDetail: (clubResourceId) => {
        router.push({
          name: "console.clubs.show",
          params: { clubResourceId: clubResourceId },
        });
      },
      goToMembers: (q) => {
        router.push({ name: "console.members", query: { q: q } });
      },
      goToArticles: () => {
        router.push({ name: "console.articles" });
      },
      goToNoticeDetail: (noticeResourceId) => {
        router.push({
          name: "console.notices.show",
          params: { noticeResourceId: noticeResourceId },
        });
      },
      setSelectedYear: (i) => {
        if (
          (i === -1 && !state.existPrevYear) ||
          (i === 1 && !state.isNextYear)
        ) {
          return;
        }
        if (i > 0) {
          state.yearIndex++;
        } else {
          state.yearIndex--;
        }

        state.selectedYear = moment()
          .add(state.yearIndex, "years")
          .format(yearFormat);
      },
    };

    return {
      state,
      actions,
      activeMeeting,
      moment,
      savedAt,
      dateFormat,
      helper,
      isDisabledMonth,
      activeMonth,
    };
  },
};
</script>

<style scoped src="./style.css"></style>
