<template>
  <dropdown
    :triggers="['click']"
    :delay="0"
    :shown="shown"
    :distance="distance"
    :auto-hide="autoHide"
    :disabled="disabled"
    :placement="placement"
  >
    <slot name="button"></slot>

    <template #popper>
      <slot name="dropdownList"></slot>
    </template>
  </dropdown>
</template>

<script>
import { reactive } from "vue";
import { Dropdown } from "floating-vue";

export default {
  name: "ButtonDropdown",
  components: {
    Dropdown,
  },
  props: {
    distance: {
      type: String,
      default: "-6",
    },
    autoHide: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    // note iphone chrome 에서 v-close-popper 디렉티브 사용시 click 이벤트 안됨.따라서 show props로 토글 처리하기!
    shown: {
      type: Boolean,
      default: false,
    },
    placement: {
      type: String,
      default: "bottom-end",
      validator(value) {
        return [
          "auto",
          "auto-start",
          "auto-end",
          "top",
          "top-start",
          "right",
          "right-start",
          "right-end",
          "bottom",
          "bottom-start",
          "bottom-end",
          "left",
          "left-start",
          "left-end",
        ].includes(value);
      },
    },
  },
  setup() {
    const state = reactive({});

    const actions = {};

    return { state, actions };
  },
};
</script>

<style scoped></style>
