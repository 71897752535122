<template>
  <div class="meeting-item">
    <div class="day-info">
      <p class="c-text" :style="state.dateStyle">
        {{ state.meetingDay }}
      </p>
      <h5 :style="state.timeStyle">{{ state.meetingTime }}</h5>
    </div>

    <div class="meeting-tit" :style="state.titleStyle">
      <h5 v-if="state.isDDay" class="text-gray-reverse">{{ meeting.title }}</h5>
      <p v-if="!state.isDDay" class="sub-text-s2">{{ meeting.title }}</p>
    </div>
  </div>
</template>

<script>
import { computed, reactive } from "vue";
import moment from "moment-timezone";

export default {
  name: "DashboardMeetingItem",
  props: {
    meeting: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const activeColor = "#56DA2A";
    const nonActiveColor = "#F6F8FA";

    const state = reactive({
      isDDay: computed(() => {
        return (
          moment(props.meeting.scheduledAt).format("YYYYMMDD") ===
          moment().format("YYYYMMDD")
        );
      }),
      meetingDay: computed(() => {
        return state.isDDay
          ? "TODAY"
          : moment(props.meeting.scheduledAt).format("MM.DD");
      }),
      meetingTime: computed(() => {
        return moment(props.meeting.scheduledAt).format("HH:mm");
      }),
      titleStyle: computed(() => {
        return {
          backgroundColor: state.isDDay ? activeColor : nonActiveColor,
        };
      }),
      dateStyle: computed(() => {
        return {
          color: state.isDDay ? activeColor : "#818287",
        };
      }),
      timeStyle: computed(() => {
        return {
          color: state.isDDay ? activeColor : "#D0D5DE",
        };
      }),
    });

    return { state };
  },
};
</script>

<style src="./style.css" scoped></style>
