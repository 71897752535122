<template>
  <div class="dashboard-fan">
    <h5>{{ number }}</h5>
    <avatar :avatar-img="fan.avatar" size="32"></avatar>
    <span class="user-name sub-text-s2" :title="state.name">
      {{ state.name }}
    </span>
    <span class="cnt sub-text-s2">{{ state.paidCount }}</span>
  </div>
</template>

<script>
import { computed, reactive } from "vue";
import helper from "@/helper";
import Avatar from "../../../../components/console/avatars/Avatar";

export default {
  name: "DashboardFanItem",
  components: { Avatar },
  props: {
    fan: {
      type: Object,
      required: true,
    },
    number: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const state = reactive({
      paidCount: computed(() => {
        return `${helper.priceFormat(props.fan.paidCount)}개`;
      }),
      name: computed(() => {
        // note 엔드유저이기 때문에 displayName을 사용하면 안된다.
        return props.fan.name;
      }),
    });

    return { state };
  },
};
</script>

<style src="./style.css" scoped></style>
